import { createSelector } from 'reselect';

const getSnackbar = state => state?.snackbar;

const EMPTY_SNACKBAR_PROPS = {};

export const getSnackbarProps = createSelector(getSnackbar, snackbar =>
  snackbar.queuedSnackbars?.length ? snackbar.queuedSnackbars[0] : EMPTY_SNACKBAR_PROPS,
);

export const getViewedSnackbars = state => state.snackbar.viewedSnackbars;
