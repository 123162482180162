import { dataLayer } from 'analytics/data-layer';

export const trackNotificationOpen = notificationId => {
  dataLayer.push({
    event: 'notification_opened',
    notificationId: notificationId || 'no_id_set',
  });
};

export const trackNotificationLinkClicked = (notificationId, notificationCtaLabel) => {
  dataLayer.push({
    event: 'notification_clicked',
    notificationId: notificationId || 'no_id_set',
    notificationCtaLabel,
  });
};

export const trackNotificationClosed = notificationId => {
  dataLayer.push({
    event: 'notification_closed',
    notificationId: notificationId || 'no_id_set',
  });
};
