import { connect } from 'react-redux';
import { getSnackbarProps } from 'redux/modules/common-snackbar/selectors';
import { viewedSnackbar } from 'redux/modules/common-snackbar/actions';
import { getIsAnyCommonModalOpen } from 'redux/modules/common-modal/selectors';

import Snackbar from './CommonSnackbar';

export default connect(
  state => ({
    isModalOpen: getIsAnyCommonModalOpen(state),
    snackbar: getSnackbarProps(state),
  }),
  {
    onClose: viewedSnackbar,
  },
)(Snackbar);
