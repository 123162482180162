import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@johnlewispartnership/wtr-ingredients/ingredients/Snackbar';
import {
  trackNotificationOpen,
  trackNotificationLinkClicked,
} from 'analytics/notification-tracking';

import { Clock } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

const iconMap = {
  Clock: <Clock />,
};

const CommonSnackbar = ({ snackbar, isModalOpen, onClose }) => {
  const handleOpen = useCallback(() => {
    trackNotificationOpen(snackbar.id);
  }, [snackbar.id]);

  const handleLinkClick = () => {
    trackNotificationLinkClicked(snackbar.id, snackbar.action.label);
  };

  const handleClose = useCallback(() => {
    onClose(snackbar.id);
  }, [snackbar.id, onClose]);

  useEffect(() => {
    if (isModalOpen && snackbar.open && onClose) {
      onClose(snackbar.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, snackbar.open, onClose]);

  const snackbarProps = {
    ...snackbar,
    onOpen: handleOpen,
    onClose: handleClose,
    icon: snackbar?.icon && iconMap[snackbar.icon],
    action: snackbar.action && { ...snackbar.action, onClick: handleLinkClick },
  };

  return !isModalOpen && <Snackbar {...snackbarProps} />;
};

CommonSnackbar.propTypes = {
  snackbar: PropTypes.shape({
    id: PropTypes.string,
    action: PropTypes.shape(),
    type: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
    icon: PropTypes.string,
    open: PropTypes.bool,
  }),
  onClose: PropTypes.func,
  isModalOpen: PropTypes.bool,
};

CommonSnackbar.defaultProps = {
  snackbar: null,
  onClose: null,
  isModalOpen: false,
};

export default CommonSnackbar;
